<template>
	<!-- 教师考勤-->
	<div class="teacherAttendance">
		<div class="section">
			<!-- 宝贝出勤 -->
			<babyAttence ref='babyAttence'></babyAttence>
		</div>
	</div>
</template>

<script>
	import {
		Image as VanImage,
		Tab,
		Tabs,
		Calendar
	} from 'vant'

	import babyAttence from '../teacherAttendance/babyAttence/index.vue'

	export default {
		name: 'teacherAttendance',
		components: {
			babyAttence,
			[VanImage.name]: VanImage,
			[Tab.name]: Tab,
			[Tabs.name]: Tabs,
			[Calendar.name]: Calendar
		},
		data() {
			return {
				tabNum: 0,
			}
		},
		mounted() {

		},
		methods: {
			// tabClick(index) {
			// 	this.tabNum = index
			// 	// 切换,宝贝考勤默认展示签到组件
			// 	this.$refs.babyAttence.isShowSign = false
			// },
		}
	}
</script>

<style lang="less">
	.teacherAttendance {
		//padding: 10px 12px;
		height: 100%;
		display: flex;
		flex-direction: column;
		.topBar {
			font-size: 14px;
			width: 72%;
			margin: 10px auto 0px;
		}
		.section{
			flex: 1;
			margin-top: 10px;
		}
	}
</style>
